import 'url-search-params-polyfill'
import '../scss/main.scss'

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import store from './store/store'
import runtimeConfig from '@jdi/vue-runtime-config'
import { LoginForm, SiteHeader, LoginDialog } from '@jdi-clients/cb-components'

const relPathMyCbAuthenticateZipcode = '/register/zipcode.json'
const relPathMyCbAuthenticate = '/authenticate.json'

Vue.use(Vuelidate)
Vue.use(runtimeConfig, { store, dispatchLoad: true, configLocation: '/public/' })

const App = () => import('./templates/App.vue')
const WasteCalendar = () => import('./templates/WasteCalendar.vue')
const UpcomingEvents = () => import('./templates/UpcomingEvents.vue')
/**
 * For more information about the specific options
 * @url https://vuejs.org/v2/api/
 *
 * During development you want this to be true, so that you are aware that the build includes warnings
 * @bool false Vue.config.productionTip
 *
 * During development you want this to be true, so that you can use Developer Tools
 * @bool true Vue.config.devtools
 *
 * During development you want this to be false, so that Vue gives you hints about your code
 * @bool true Vue.config.silent
 *
 * Set this to true to enable component init, compile, render and patch performance tracing in the browser devtool performance/timeline panel.
 * Only works in development mode and in browsers that support the performance.mark API.
 * @bool false Vue.config.performance
 */

if (process.env.NODE_ENV === 'production') {
  Vue.config.productionTip = false
  Vue.config.devtools = false
  Vue.config.silent = true
  Vue.config.performance = false
} else {
  Vue.config.productionTip = false
  Vue.config.devtools = true
  Vue.config.silent = false
  Vue.config.performance = false
}

/* eslint-disable no-new */
if (document.querySelector('#site-header')) {
  new Vue({
    components: {
      'site-header': SiteHeader
    }
  }).$mount('#site-header')
}

if (document.querySelector('#vueApp')) {
  new Vue({
    store,
    el: '#vueApp',
    components: {
      App
    },
    data() {
      return {
        forcedServiceId: -1,
        account: {}
      }
    },
    beforeMount() {
      const {
        forcedServiceId,
        accountName,
        accountAddress,
        accountZipCode,
        accountResidence,
        accountPhoneNumber,
        accountPassNumber,
        accountEmailAddress
      } = this.$el.dataset
      this.forcedServiceId = forcedServiceId !== undefined && forcedServiceId !== '' ? parseInt(forcedServiceId) : -1
      this.account.name = accountName !== undefined && accountName !== '' ? accountName : ''
      this.account.address = accountAddress !== undefined && accountAddress !== '' ? accountAddress : ''
      this.account.zipCode = accountZipCode !== undefined && accountZipCode !== '' ? accountZipCode : ''
      this.account.residence = accountResidence !== undefined && accountResidence !== '' ? accountResidence : ''
      this.account.phoneNumber = accountPhoneNumber !== undefined && accountPhoneNumber !== '' ? accountPhoneNumber : ''
      this.account.passNumber = accountPassNumber !== undefined && accountPassNumber !== '' ? accountPassNumber : ''
      this.account.emailAddress = accountEmailAddress !== undefined && accountEmailAddress !== '' ? accountEmailAddress : ''
    },
    template: `<App />`
  })
}

if (document.querySelector('#waste-calendar')) {
  new Vue({
    store,
    el: '#waste-calendar',
    components: { WasteCalendar },
    data() {
      return {
        url: undefined
      }
    },
    beforeMount() {
      const { url } = this.$el.dataset
      this.url = url
    },
    template: '<WasteCalendar />'
  })
}

if (document.querySelector('#upcoming-events')) {
  new Vue({
    store,
    el: '#upcoming-events',
    components: { UpcomingEvents },
    data() {
      return {
        url: undefined,
        dumplocationsInfoTop: undefined,
        dumplocationsInfoBottom: undefined
      }
    },
    beforeMount() {
      const { url, dumplocationsInfoTop, dumplocationsInfoBottom } = this.$el.dataset
      this.url = url
      this.dumplocationsInfoTop = dumplocationsInfoTop
      this.dumplocationsInfoBottom = dumplocationsInfoBottom
    },
    template: '<UpcomingEvents />'
  })
}

if (document.querySelector('#login-app')) {
  new Vue({
    store,
    el: '#login-app',
    components: {
      'login-form': LoginForm
    }
  })
}

const loginDialogTriggers = document.querySelectorAll('.login-dialog')

if (loginDialogTriggers.length) {
  const instanceContainer = document.createElement('div')
  const component = document.createElement('login-dialog')
  instanceContainer.id = 'login-dialog'
  component.setAttribute('rel-path-my-cb-authenticate-zipcode', relPathMyCbAuthenticateZipcode)
  component.setAttribute('rel-path-my-cb-authenticate', relPathMyCbAuthenticate)
  instanceContainer.append(component)
  document.querySelector('body').append(instanceContainer)

  setTimeout(() => {
    new Vue({
      components: {
        LoginDialog
      }
    }).$mount('#login-dialog')
  }, 1000)
}
