export default {
  getStreams(state) {
    return state.streams
  },
  getStreamIconByCode: state => code => {
    const stream = state.streams.find(item => {
      const index = item.code.indexOf(code)
      if (index !== -1) {
        return state.streams[item.code.indexOf(code)]
      }
    })
    if (stream && stream.icon) {
      return stream.icon
    }
  }
}
